<template>
  <div>
    <list-template
      :table-config="tableConfig"
      :table-data="tableData"
      :total="total"
      :loading="loading"
      :current-page="page"
      @onChangePage="handleCurrentChange"
      @onHandle="handleClick"
      @onChange="onChange"
      @onAdd="handleAdd"
    >
    </list-template>
    <Dialog ref="dialogDom" :visible.sync="visible" :title="title" size="small" buttonAlign="center" submitText="保存" :beforeSubmit="onBeforeClose">
      <el-form ref="form" :model="formData" :rules="rules" label-position="left">
        <el-form-item label="线索来源名称" prop="source">
          <el-input style="width: 100% !important;" v-model="formData.source" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
    </Dialog>
  </div>
</template>

<script>
import { tableListMixin } from '@/utils/mixins'
import Dialog from "@/components/common/Dialog";
import http from "@/api";
export default {
	_config:{"route":{"path":"list","meta":{"title":"列表"}}},
  components: { Dialog },
  mixins: [tableListMixin],
  methods: {
    getData() {
      this.getDataApi('/zhaosheng/api/crm/source-list?type=2')
    },
    handleClick(row, handle) {
      this.title = '编辑'
      this.visible = true
      this.formData.id = row.id
      this.formData.source = row.source
    },
    handleAdd() {
      this.title = '新增'
      this.formData.source = ''
      this.formData.id = ''
      this.visible = true
    },
    handleSubmit(name) {
      this.$refs[name].validate(valid => {
        if(valid) {
          this.$refs.dialogDom.onSubmit()
        }
      })
    },
    handleClose() {
      this.visible = false
    },
    onBeforeClose(done, instance) {
      this.$refs['form'].validate(valid => {
        if(valid) {
          const api = `/zhaosheng/api/crm/${this.formData.id ? 'update' : 'add'}-source`
          instance.confirmButtonLoading = true;
          instance.confirmButtonText = '保存中...';
          http.post(api, { ...this.formData, type: 2 }).then(res => {
            this.$message.success(`${this.title}成功`);
            this.getData()
            done()
          }).catch(() => {
            instance.confirmButtonLoading = false;
            instance.confirmButtonText = '确定';
          })
        }
      })
    }
  },
  data() {
    return {
      title: '',
      visible: false,
      formData: { source: '', id: '' },
      rules: {
        source: [{ required: true, message: '请输入', trigger: 'change' }],
      },
      tableConfig: [
        {
          prop: 'source',
          label: '线索来源',
        },
        {
          prop: 'creator',
          label: '创建人',
        },
        {
          prop: 'created_at',
          label: '创建时间',
        },
        {
          label: '操作',
          width: '100px',
          handle: true,
          fixed: 'right',
          render() {
            return ['编辑']
          }
        }
      ]
    }
  },
}
</script>

<style lang="scss" scoped></style>
